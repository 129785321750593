import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  Row,
  Table,
} from "reactstrap";
import Breadcrumbs from "../layout/breadcrumb";
import Layout from "./Layout";
import Loader from "../layout/loader/Loader";
import { getMethodAuth } from "../api";
import { useNavigate, useParams } from "react-router";
import Pagination from "../utils/Pagination";
import TableScroll from "../utils/TableScroll";
import DateRangeFormat from "../utils/DateRangeFormat";
import { calculateTotal, decodeData } from "../utils/Helper";
import { STREAMER_REPORT_API } from "../api/routes";
const ReportStreamerDetail = () => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [from, setFrom] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const params = useParams();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [refr, setRefr] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      let token = decodeData(localStorage.getItem("y_g_n_d_a"));
      if (token) {
        let startD = startDate? `&start_date=${startDate}`:'';
        let endD = endDate? `&end_date=${endDate}`:'';
        let res = await getMethodAuth(
          `${STREAMER_REPORT_API}?type=user&id=${
            params.streamerId
          }&page=${page}${startD}${endD}`,
          token.token
        );
        res && setLoading(false);
        if (res?.status === "success") {
          setUsers(res?.data?.data);
          if (res?.data) {
            if (res?.data?.meta?.from) {
              setFrom(res.data.from);
            }
            if (res.data?.meta?.last_page) {
              setTotalPage(res.data.last_page);
            }
          }
        };
      }
    })();
  }, [refr]);

  const searchSubmitHandler = () => {
    setRefr(!refr);
  };

  return (
    <>
      <Layout>
        <Breadcrumbs
          title={`Streamer - ${users.length > 0 && users[0].streamer_name}`}
        />
        <Container fluid={true}>
        {loading && <Loader />}
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className="py-4 d-flex justify-content-end">
                  <Col sm="12">
                    <Form>
                      <Row className="justify-content-end">
                        <Col md="4 px-1 mb-2">
                          <DateRangeFormat
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                          />
                        </Col>
                        <Col md="4 px-1 mb-2">
                          <button
                            className="w-100 btn btn-primary"
                            disabled={loading}
                            type="button"
                            onClick={searchSubmitHandler}
                          >
                            {loading ? "Loading..." : "Submit"}
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </CardHeader>
                <CardBody className="position-relative">
                  <TableScroll>
                    <Table className="table-border-vertical">
                      <thead>
                        <tr className="bg-dark">
                          <th className="text-center text-white" scope="col">
                            <b>No.</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>ID</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Name</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>D Count</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>W Count</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>D Amount</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>W Amount</b>
                          </th>
                          <th className="text-center text-white" scope="col">
                            <b>Profit</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {users?.length > 0 &&
                          users?.map((user, i) => (
                            <tr key={i}>
                              <td className="text-center" scope="row">
                                {i + from}.
                              </td>
                              <td className="text-center" scope="row">
                                {user.id}
                              </td>
                              <td className="text-center" scope="row">
                                {user.name}
                              </td>
                              <td className="text-center" scope="row">
                                {user.deposit_count}
                              </td>
                              <td className="text-center" scope="row">
                                {user.withdraw_count}
                              </td>
                              <td className="text-end" scope="row">
                                {user.deposit_amount?.toLocaleString("en-us")}
                              </td>
                              <td className="text-end" scope="row">
                                {user.withdraw_amount?.toLocaleString("en-us")}
                              </td>
                              <td className="text-end" scope="row">
                                {user.profit?.toLocaleString("en-us")}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                      <tfoot>
                        <tr className="bg-dark">
                          <td colSpan={3} className="text-end text-white">
                            Total
                          </td>
                          <td className="text-center text-white">
                            {calculateTotal(users, "deposit_count")}
                          </td>
                          <td className="text-center text-white">
                            {calculateTotal(users, "withdraw_count")}
                          </td>
                          <td className="text-end text-white">
                            {calculateTotal(users, "deposit_amount")}
                          </td>
                          <td className="text-end text-white">
                            {calculateTotal(users, "withdraw_amount")}
                          </td>
                          <td className="text-end text-white">
                            {calculateTotal(users, "profit")}
                          </td>
                        </tr>
                      </tfoot>
                    </Table>
                  </TableScroll>
                </CardBody>
                {totalPage > 1 && (
                  <CardFooter className="d-flex justify-content-end">
                    <Pagination
                      setPage={setPage}
                      totalPage={totalPage}
                      setLoading={setLoading}
                    />
                  </CardFooter>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
};

export default ReportStreamerDetail;
